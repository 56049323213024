<template>
  <article id="wrap" ontouchstart="">
    <AppHeader theme="white">特定商取引法に基づく表記</AppHeader>

    <section class="contents">
      <div class="contentInner pb_80">
        <div class="white_bg_area2">
          <div class="item">
            <div class="item_title">サービス名</div>
            <div>ステキナセカイ</div>
          </div>

          <div class="item">
            <div class="item_title">サービス提供事業者</div>
            <div>株式会社ハイスピードボーイ</div>
          </div>

          <div class="item">
            <div class="item_title">代表者名</div>
            <div>染谷 宗宏</div>
          </div>

          <div class="item">
            <div class="item_title">所在地</div>
            <div>
              〒151-0064<br />
              東京都渋谷区上原2-28-3リバーワン代々木上原201
            </div>
          </div>
          <div class="item">
            <div class="item_title">お問合せ</div>
            <div>「info@sutekinasekai.net」までご連絡ください。</div>
            <div>
              ※
              サービに関するお問合せは電話では受け付けておりません。弊社電話番号が必要なお客様は上記フォームよりお申し出ください。遅滞なく開示いたします。
            </div>
          </div>

          <div class="item">
            <div class="item_title">サービス提供価格, 販売価格</div>
            <div>トークルームに入会時の手続きの際に画面に表示されます。<br>
              コンテンツの売買の場合は、提供されるコンテンツの画面に表示されます。
              その他、サービスサイト上に記載の利用料が発生します。
            </div>
          </div>

          <div class="item">
            <div class="item_title">
              サービスの対価以外にお客様に発生する費用
            </div>
            <ul>
              <li>
                PCを利用する場合<br />
                PCでインターネットをするために必要となる通信料、プロパイダ料金、その他インターネット利用のため必要となる料金
              </li>
              <li>
                スマートフォンを利用する場合<br />
                パケット通信料
              </li>
            </ul>
          </div>

          <div class="item">
            <div class="item_title">支払方法</div>
            <div>
              クレジットカード決済<br>
              (Visa/Master/JCB/American Express/Diners Club/Discoverがご利用いただけます)
            </div>
          </div>

          <div class="item">
            <div class="item_title">支払時期</div>
            <div>
              「すてきなせかい」は月単位のサービスです。月額料金は、購入手続きが完了した日に最初の請求が発生し、その後毎月同じ日に請求が行われます。１月３１日など指定日がない場合は、当該月の月末に行われ、その後は当該日に固定されます。その他コンテンツの販売については、コンテンツ注文時に支払いが発生します。
            </div>
          </div>

          <div class="item">
            <div class="item_title">サービス提供方法</div>
            <div>代金決済手続きの完了後直ちに</div>
          </div>

          <div class="item">
            <div class="item_title">サービス開始後のキャンセルについて</div>
            <div>サービスの性質上、キャンセルには応じられません。</div>
          </div>

          <div class="item">
            <div class="item_title">動作環境</div>
            <div>（動作環境の確認参照）</div>
          </div>

          <div class="item">
            <div class="item_title">注意事項</div>
            <ul>
              <li>クーリングオフが適応されるサービスではありません。</li>
              <li>
                月の途中で解約になった場合、１ヶ月分の料金が発生し、日割精算等による返金を含めた一切の返金は行われません。
              </li>
            </ul>
          </div>

          <div class="item">
            <div class="item_title">解約について</div>
            <div>
              解約はいつでもマイページから行っていただけます。<br>
              次回決済日までに解約をしていただければ、次回以降の請求は発生いたしません。<br>
              なお、日割り分等での返金は承っておりませんのでご了承ください。
            </div>
          </div>

          <div class="item">
            <div class="item_title">返品・不良品交換について</div>
            <div>
              お客様都合による返品は承っておりません。<br>
              不良品の場合、コンテンツ販売者ご連絡ください。
              返品の際の送料はお客様負担といたします。
            </div>
          </div>          
        </div>
        <!-- .white_bg_area2 -->
      </div>
      <!--.contentInner-->
    </section>
    <!--.contents-->

    <AppFooter fixed theme="yellow"></AppFooter>
  </article>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

export default {
  name: "Law",
  components: { AppHeader, AppFooter },
};
</script>

<style scoped>
ul {
  list-style-type: disc;
  margin-top: 10px;
  margin-left: 18px;
}
</style>
